import { assign, setup } from 'xstate';

import { isLumpSumWithdrawalAmountUnderLimit } from './utils';

export const flowId = 'withdrawalMiniJourney';

export const Steps = {
  assessingPension: 'assessingPension',
  importantConsiderations: 'importantConsiderations',
  withdrawalAdvice: 'withdrawalAdvice',
  taxImplications: 'taxImplications',
  withdrawalCharges: 'withdrawalCharges',
  withdrawalInvestment: 'withdrawalInvestment',
  benefits: 'benefits',
  debt: 'debt',
  health: 'health',
  withdrawalOptions: 'withdrawalOptions',
  withdrawalAmount: 'withdrawalAmount',
  bookAppointment: 'bookAppointment',
  withDrawalReason: 'withDrawalReason',
  withDrawalResults: 'withDrawalResults',
  withdrawalOtherOptions: 'withdrawalOtherOptions',
  withdrawalInfluenced: 'withdrawalInfluenced',
  taxImplicationsContinued: 'taxImplicationsContinued',
  withdrawalAccountDetails: 'withdrawalAccountDetails',
  withdrawalConfirmation: 'withdrawalConfirmation',
  withdrawalRequestComplete: 'withdrawalRequestComplete',
  vulnerableCustomer: 'vulnerableCustomer',
  withdrawalInProgress: 'withdrawalInProgress',
  appointmentPending: 'appointmentPending',
};

export const Guards = {
  canContinueWithdrawal: 'canContinueWithdrawal',
  canViewEstimatedIncome: 'canViewEstimatedIncome',
  isWithdrawalTypeLumpSum: 'isWithdrawalTypeLumpSum',
};

const WITHDRAWAL_TYPES = {
  LUMP_SUM: 'lump-sum',
  REGULAR: 'regular',
};

export const InitContext = {
  accountNumber: '',
  balanceAtRequestTime: 0,
  bankName: '',
  canFetchEstimatedIncome: false,
  canRequestOnlineWithdrawal: false,
  completeContributionRequestSelected: false,
  completePensionRequestSelected: false,
  confirmWithdrawal: false,
  confirmWithdrawalAdvice: false,
  fundsUsedFor: '',
  fundsUsedForSelectedOption: '',
  healthIssues: false,
  name: '',
  sortCode: '',
  withdrawalAmount: 0,
  withdrawalAmountMask: '£0',
  withdrawalType: WITHDRAWAL_TYPES.LUMP_SUM,
  rollNo: '',
  type: `customer-withdrawal-${WITHDRAWAL_TYPES.LUMP_SUM}`,
  understandWithdrawal: false,
  onVulnerableWatchlist: false,
};

export const machine = setup({
  types: {
    context: {},
    events: {},
  },
  guards: {
    [Guards.canContinueWithdrawal]: ({ context }) => {
      const underLimit = isLumpSumWithdrawalAmountUnderLimit(context.withdrawalAmount);
      return (context.canRequestOnlineWithdrawal || underLimit)
        && context.withdrawalType === WITHDRAWAL_TYPES.LUMP_SUM
        && !context.onVulnerableWatchlist
    },
    [Guards.canViewEstimatedIncome]: ({ context }) => context.canFetchEstimatedIncome,
    [Guards.isWithdrawalTypeLumpSum]: ({ context }) => {
      return context.withdrawalType === WITHDRAWAL_TYPES.LUMP_SUM;
    },
  },
}).createMachine({
  id: `${flowId}-machine`,
  initial: Steps.assessingPension,
  context: ({ input }) => ({
    ...InitContext,
    ...(input?.balanceAtRequestTime ? { balanceAtRequestTime: input.balanceAtRequestTime } : {}),
    ...(input?.canRequestOnlineWithdrawal ? {
      canRequestOnlineWithdrawal: input.canRequestOnlineWithdrawal,
    } : {}),
    ...(input?.canFetchEstimatedIncome ? {
      canFetchEstimatedIncome: input.canFetchEstimatedIncome,
    } : {}),
    ...(input?.onVulnerableWatchlist ? {
      onVulnerableWatchlist: input.onVulnerableWatchlist,
    } : {}),
  }),
  on: {
    vulnerableCustomer: { target: `.${Steps.vulnerableCustomer}` },
    withdrawalInProgress: { target: `.${Steps.withdrawalInProgress}` },
    appointmentPending: { target: `.${Steps.appointmentPending}` },
  },
  states: {
    assessingPension: {
      meta: { index: 1 },
      on: {
        next: {
          target: Steps.importantConsiderations,
        },
      },
    },
    importantConsiderations: {
      meta: { index: 2 },
      on: {
        next: {
          target: Steps.withdrawalAdvice,
        },
        back: {
          target: Steps.assessingPension,
        },
      },
    },
    withdrawalAdvice: {
      meta: { index: 3 },
      on: {
        next: {
          target: Steps.taxImplications,
        },
        back: {
          target: Steps.importantConsiderations,
        },
      },
    },
    taxImplications: {
      meta: { index: 4 },
      on: {
        next: {
          target: Steps.withdrawalCharges,
        },
        back: {
          target: Steps.withdrawalAdvice,
        },
      },
    },
    withdrawalCharges: {
      meta: { index: 5 },
      on: {
        next: {
          target: Steps.withdrawalInvestment,
        },
        back: {
          target: Steps.taxImplications,
        },
      },
    },
    withdrawalInvestment: {
      meta: { index: 6 },
      on: {
        next: {
          target: Steps.benefits,
        },
        back: {
          target: Steps.withdrawalCharges,
        },
      },
    },
    benefits: {
      meta: { index: 7 },
      on: {
        next: {
          target: Steps.debt,
        },
        back: {
          target: Steps.withdrawalInvestment,
        },
      },
    },
    debt: {
      meta: { index: 8 },
      on: {
        next: {
          target: Steps.health,
        },
        back: {
          target: Steps.benefits,
        },
      },
    },
    health: {
      meta: { index: 9 },
      on: {
        next: {
          target: Steps.withdrawalOptions,
        },
        back: {
          target: Steps.debt,
        },
      },
    },
    withdrawalOptions: {
      meta: { index: 10 },
      on: {
        changeType: {
          actions: assign({
            type: ({ event: { fullWithdrawalType } }) => fullWithdrawalType,
            withdrawalType: ({ event: { withdrawalType } }) => withdrawalType,
          }),
        },
        next: {
          guard: Guards.isWithdrawalTypeLumpSum,
          target: Steps.withdrawalAmount,
        },
        back: {
          target: Steps.health,
        },
      },
    },
    withdrawalAmount: {
      meta: { index: 11 },
      on: {
        back: {
          target: Steps.withdrawalOptions,
        },
        changeWithdrawalAmount: {
          actions: assign({
            withdrawalAmount: ({ event: { withdrawalAmount } }) => withdrawalAmount,
            withdrawalAmountMask: ({ event: { withdrawalAmountMask } }) => withdrawalAmountMask,
          }),
        },
        nextLumpSum: [
          {
            guard: Guards.canContinueWithdrawal,
            target: Steps.withDrawalReason,
          },
          {
            target: Steps.bookAppointment,
          },
        ],
        bookAppointment: {
          target: Steps.bookAppointment,
        },
      },
    },
    bookAppointment: {
      meta: { index: 12 },
      on: {
        back: {
          target: Steps.withdrawalAmount,
        },
      },
    },
    withDrawalReason: {
      meta: { index: 12 },
      on: {
        changeWithdrawalReason: {
          actions: assign({
            fundsUsedFor: ({ event: { fundsUsedFor } }) => fundsUsedFor,
            fundsUsedForSelectedOption:
              ({ event: { fundsUsedForSelectedOption } }) => fundsUsedForSelectedOption,
          }),
        },
        next: [
          {
            guard: Guards.canViewEstimatedIncome,
            target: Steps.withDrawalResults,
          },
          {
            target: Steps.withdrawalOtherOptions,
          },
        ],
        back: {
          target: Steps.withdrawalAmount,
        },
      },
    },
    withDrawalResults: {
      meta: { index: 13 },
      on: {
        changeCompletePensionRequestSelected: {
          actions: assign({
            completePensionRequestSelected:
              ({ event: { completePensionRequestSelected } }) => completePensionRequestSelected,
          }),
        },
        changeCompleteContributionRequestSelected: {
          actions: assign({
            completeContributionRequestSelected: ({
              event: {
                completeContributionRequestSelected,
              },
            }) => completeContributionRequestSelected,
          }),
        },
        next: {
          target: Steps.withdrawalOtherOptions,
        },
        back: {
          target: Steps.withDrawalReason,
        },
      },
    },
    withdrawalOtherOptions: {
      meta: { index: 14 },
      on: {
        next: {
          target: Steps.withdrawalInfluenced,
        },
        back: [
          {
            guard: Guards.canViewEstimatedIncome,
            target: Steps.withDrawalResults,
          },
          {
            target: Steps.withDrawalReason,
          },
        ],
      },
    },
    withdrawalInfluenced: {
      meta: { index: 15 },
      on: {
        next: {
          target: Steps.taxImplicationsContinued,
        },
        back: {
          target: Steps.withdrawalOtherOptions,
        },
      },
    },
    taxImplicationsContinued: {
      meta: { index: 16 },
      on: {
        next: {
          target: Steps.withdrawalAccountDetails,
        },
        back: {
          target: Steps.withdrawalInfluenced,
        },
      },
    },
    withdrawalAccountDetails: {
      meta: { index: 17 },
      on: {
        changeName: {
          actions: assign({
            name: ({ event: { name } }) => name,
          }),
        },
        changeSortCode: {
          actions: assign({
            sortCode: ({ event: { sortCode } }) => sortCode,
          }),
        },
        changeAccountNumber: {
          actions: assign({
            accountNumber: ({ event: { accountNumber } }) => accountNumber,
          }),
        },
        changeBankName: {
          actions: assign({
            bankName: ({ event: { bankName } }) => bankName,
          }),
        },
        changeRollNo: {
          actions: assign({
            rollNo: ({ event: { rollNo } }) => rollNo,
          }),
        },
        next: {
          target: Steps.withdrawalConfirmation,
        },
        back: {
          target: Steps.taxImplicationsContinued,
        },
      },
    },
    withdrawalConfirmation: {
      meta: { index: 18 },
      on: {
        changeConfirmWithdrawal: {
          actions: assign({
            confirmWithdrawal: ({ event: { confirmWithdrawal } }) => confirmWithdrawal,
          }),
        },
        changeUnderstandWithdrawal: {
          actions: assign({
            understandWithdrawal: ({ event: { understandWithdrawal } }) => understandWithdrawal,
          }),
        },
        changeConfirmWithdrawalAdvice: {
          actions: assign({
            confirmWithdrawalAdvice:
              ({ event: { confirmWithdrawalAdvice } }) => confirmWithdrawalAdvice,
          }),
        },
        changeAccountDetails: {
          target: Steps.withdrawalAccountDetails,
        },
        changeWithdrawalAmount: {
          target: Steps.withdrawalAmount,
        },
        next: {
          target: Steps.withdrawalRequestComplete,
        },
        back: {
          target: Steps.withdrawalAccountDetails,
        },
      },
    },
    withdrawalRequestComplete: {
      meta: { index: 19 },
      type: 'final',
    },
    vulnerableCustomer: {
      type: 'final',
    },
    withdrawalInProgress: {
      type: 'final',
    },
    appointmentPending: {
      type: 'final',
    },
  },
});
