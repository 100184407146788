import {
  InvestmentRecommendationCustomerDataSetPage,
  OngoingCustomerDataSetPage,
} from '../CustomerDataSetPage/CustomerDataSetPage';
import VerifyPage from '../VerifyPage/VerifyPage';
import { JOURNEYS } from '../../util/constants';
import DashboardPage from '../DashboardPage/DashboardPage';
import FindPensionsPage from '../FindPensionsPage/FindPensionsPage';
import AcceptChangeController from '../AcceptChangeController/AcceptChangeController';
import AddPensionsPage from '../AddPensionsPage/AddPensionsPage';
import MissingDetailsRouter from '../MissingDetailsRouter/MissingDetailsRouter';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import VulnerableCustomerPage from '../VulnerableCustomerPage/VulnerableCustomerPage';
import SetupContributionPage from '../SetupContributionPage/SetupContributionPage';
import SetupLumpSumPage from '../SetupLumpSumPage/SetupLumpSumPage';
import SetupWithdrawalPage from '../SetupWithdrawalPage/SetupWithdrawalPage';
import MarketingPreferences from '../MarketingPreferencesPage/MarketingPreferences';
import WithdrawalAdviserPage from '../WithdrawalAdviserPage/WithdrawalAdviserPage';
import PlanActivityPage from '../PlanActivityPage/PlanActivityPage';
import RetirementIncomePage from '../RetirementIncomePage/RetirementIncomePage';
import AddMoneyOption from '../AddMoneyOption/AddMoneyOption';
import WithdrawalMiniJourney from '../WithdrawalMiniJourney/WithdrawalMiniJourney';
import PlanSummaryPage from '../PlanSummaryPage/PlanSummaryPageWrapper';
import SerpsMissingDetails from '../SerpsMissingDetails/SerpsMissingDetails';
import SerpsAuthorisePensionsThankYou from '../SerpsAuthorisePensions/ThankYou';
import SerpsAuthorisePensionsSteps from '../SerpsAuthorisePensions/SerpsAuthorisePensionsSteps';
import ImportantConsiderationsJourney from '../WithdrawalMiniJourney/ImportantConsiderationsJourney';

export default {
  [JOURNEYS.CDS]: InvestmentRecommendationCustomerDataSetPage,
  [JOURNEYS.VERIFY]: VerifyPage,
  [JOURNEYS.DASHBOARD]: DashboardPage,
  [JOURNEYS.ONGOING_CDS]: OngoingCustomerDataSetPage,
  [JOURNEYS.AUTHORISE_CHANGE]: AcceptChangeController,
  [JOURNEYS.ADD_PENSIONS]: AddPensionsPage,
  [JOURNEYS.FIND_PENSIONS]: FindPensionsPage,
  [JOURNEYS.PERSONAL_DETAILS]: PersonalDetails,
  [JOURNEYS.VULNERABLE_CUSTOMER]: VulnerableCustomerPage,
  [JOURNEYS.SETUP_CONTRIBUTION]: SetupContributionPage,
  [JOURNEYS.SETUP_LUMP_SUM]: SetupLumpSumPage,
  [JOURNEYS.MISSING_DETAILS]: MissingDetailsRouter,
  [JOURNEYS.SERPS_MISSING_DETAILS]: SerpsMissingDetails,
  [JOURNEYS.SERPS_AUTH]: SerpsAuthorisePensionsSteps,
  [JOURNEYS.SERPS_AUTH_THANK_YOU]: SerpsAuthorisePensionsThankYou,
  [JOURNEYS.SETUP_WITHDRAWAL_MONTHLY]: SetupWithdrawalPage,
  [JOURNEYS.SETUP_WITHDRAWAL_LUMP_SUM]: SetupWithdrawalPage,
  [JOURNEYS.WITHDRAWAL_LUMP_SUM_OVER_LIMIT]: WithdrawalAdviserPage,
  [JOURNEYS.MARKETING_PREFERENCES]: MarketingPreferences,
  [JOURNEYS.PLAN_SUMMARY]: PlanSummaryPage,
  [JOURNEYS.PLAN_ACTIVITY]: PlanActivityPage,
  [JOURNEYS.RETIREMENT_INCOME_PROJECTION]: RetirementIncomePage,
  [JOURNEYS.ADD_MONEY_OPTION]: AddMoneyOption,
  [JOURNEYS.WITHDRAWAL_MINI_JOURNEY]: WithdrawalMiniJourney,
  [JOURNEYS.IMPORTANT_CONSIDERATIONS_JOURNEY]: ImportantConsiderationsJourney,
};
