import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
  withdrawalOtherOptionsStepVisitedAction,
} from '../../redux/modules/withdrawal';

import WithdrawalInfluencedImage from '../../assets/images/withdrawals/speech-bubble.svg';

import styles from './WithdrawalPage.css';

const WithdrawalInfluenced = ({
  step,
  totalSteps,
  onBack,
  onNext,
  dispatchWithdrawalOtherOptionsStepVisited,
  dispatchNextClicked,
  dispatchBackClicked,
}) => {
  useEffect(() => {
    dispatchWithdrawalOtherOptionsStepVisited();
  }, []);

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '105px' }} src={WithdrawalInfluencedImage} alt="books" />
        </div>
        <h2 className={styles.headingTwo}>{'Have you been influenced to make this decision?'}</h2>
        <p className={styles.body}>{'Your pension is an important asset for your future, and we want to ensure you’re making the right choice. If you’re unsure or feel external pressures, our Pension Advisers are on hand to help.'}</p>
        <p className={styles.body}>{'We’re committed to safeguarding your financial well-being and ensuring you make informed decisions. Please confirm that the decision to withdraw from your pension is your own.'}</p>
        <br />
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalInfluencedStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
                  <span>
                    <ButtonLink
                      variant="primary"
                      displayAsHref
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'message them here'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        <Button
          size="large"
          label={'I confirm'}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchWithdrawalOtherOptionsStepVisited: withdrawalOtherOptionsStepVisitedAction,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalInfluenced);
