import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import {
  backClickedAction,
  withdrawalMessageAdvisorButtonClickedAction,
  withdrawalImportantConsiderationsClickedAction,
} from '../../redux/modules/withdrawal';

import withdrawalPlantImg from '../../assets/images/retirementIncome/withdrawal-plant.svg';
import chevronRight from '../../assets/images/rebranding/chevron-right.svg';
import styles from '../WithdrawalsHubPage/WithdrawalsHubPage.css';

const WithdrawalInProgress = ({
  onBack,
  dispatchBackClicked,
  dispatchWithdrawalMessageAdvisorButtonClicked,
  dispatchWithdrawalImportantConsiderationsClicked,
  history,
}) => {
  return (
    <BaseGrid addPensionsTheme greyTheme>
      <div className={styles.container}>
        <ButtonLink
          label="← Back"
          onClick={() => {
            dispatchBackClicked();
            onBack();
          }}
        />
        <div className={classnames(styles.roundedDiv)}>
          <div className={styles.roundedDivHeading}>
            <img
              src={withdrawalPlantImg}
              alt={'Withdrawal plant'}
            />
            <h1 className={styles.withdrawalHeading}>{'Pension withdrawals'}</h1>
          </div>
          <div className={styles.withdrawalText}>
            {'We are currently processing a withdrawal you have requested. If you wish to amend this or if you have any questions please '}
            <ButtonLink
              variant="primary"
              label="message your adviser."
              to="/inbox/new-message"
              search={'?subject=Withdrawal%20Request'}
              displayAsHref
              font="bodyOne"
              onClick={dispatchWithdrawalMessageAdvisorButtonClicked}
            />
          </div>
        </div>
        <button
          className={classnames(styles.informationCard)}
          type="button"
          to="/important-considerations"
          onClick={() => {
            dispatchWithdrawalImportantConsiderationsClicked();
            history.push('/important-considerations');
          }}
        >
          <p className={styles.withdrawalInfoHeading}>{'Important considerations'}</p>
          <div className={styles.chevronRight}>
            <img src={chevronRight} alt="Right Chevron" />
          </div>
        </button>
        <div className={styles.withdrawalContactUsWrapper}>
          <ContactUsContent
            showButton={false}
            headingRowStyles={{ alignItems: 'flex-start' }}
            customMessage={(
              <p
                className={styles.withdrawalContactUsMessage}
              >
                {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
                <ButtonLink
                  variant="primary"
                  label="message them here"
                  to="/inbox/new-message"
                  search={'?subject=Withdrawal%20Request'}
                  displayAsHref
                  font="bodyOne"
                  onClick={dispatchWithdrawalMessageAdvisorButtonClicked}
                />
                {'.'}
              </p>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

WithdrawalInProgress.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchBackClicked: backClickedAction,
  dispatchWithdrawalMessageAdvisorButtonClicked: withdrawalMessageAdvisorButtonClickedAction,
  dispatchWithdrawalImportantConsiderationsClicked: withdrawalImportantConsiderationsClickedAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(WithdrawalInProgress);
