import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import AssessingPension from './AssessingPension';
import ImportantConsiderations from './ImportantConsiderations';
import TaxImplications from './TaxImplications';
import WithdrawalAdvice from './WithdrawalAdvice';
import WithdrawalCharges from './WithdrawalCharges';
import WithdrawalInvestment from './WithdrawalInvestment';
import Benefits from './Benefits';
import Health from './Health';
import Debt from './Debt';
import Alternatives from './Alternatives';
import CombinePensions from './CombinePensions';

import { backClickedAction } from '../../redux/modules/withdrawal';
import { machine, Steps, flowId } from './machine/importantConsiderations';

const ImportantConsiderationsJourney = ({
  history,
  dispatchBackClicked,
}) => {
  const totalSteps = Object.keys(Steps).length + 1;

  const [state, send] = useMachine(machine);

  const stateMeta = state.getMeta();
  const machineStepMeta = `${flowId}-machine.${state.value}`;
  const currentStepIndex = get(stateMeta[machineStepMeta], 'index', 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  return (
    <>
      {state.matches(Steps.assessingPension) && (
        <AssessingPension
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => {
            dispatchBackClicked();
            history.push('/withdrawals');
          }}
        />
      )}
      {state.matches(Steps.importantConsiderations) && (
        <ImportantConsiderations
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.withdrawalAdvice) && (
        <WithdrawalAdvice
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.taxImplications) && (
        <TaxImplications
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.withdrawalCharges) && (
        <WithdrawalCharges
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.withdrawalInvestment) && (
        <WithdrawalInvestment
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.benefits) && (
        <Benefits
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.debt) && (
        <Debt
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.health) && (
        <Health
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          hideContactUs
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.alternatives) && (
        <Alternatives
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          nextButtonText={'Continue'}
        />
      )}
      {state.matches(Steps.combinePensions) && (
        <CombinePensions
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => {
            history.push('/withdrawals');
          }}
          onBack={() => send({ type: 'back' })}
        />
      )}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchBackClicked: backClickedAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ImportantConsiderationsJourney));
