import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import styles from './CdsModal.css';
import Modal from '../../components/Modal/Modal';
import GlanceCard from '../../components/GlanceCard/GlanceCard';
import GlanceCardContent from '../../components/GlanceCard/GlanceCardContent';

import greenTick from '../../assets/images/green-tick-update-cds.svg';

const CdsModal = ({
  show,
  onClose,
  history,
}) => {
  return (
    <Modal show={show} backDropClick={onClose} centerContent>
      <GlanceCard>
        <GlanceCardContent>
          <div className={styles.modalHeaderSection}>
            <img src={greenTick} alt="Green tick" className={styles.image} />
            <h3 className={styles.modalHeading}>{'We need some information'}</h3>
          </div>
          <p className={styles.body}>
            {'The financial markets have seen a lot of change over the past year, to allow us to best advise on your withdrawal, we need you to answer a few simple questions. Then we can provide a more accurate projection on the impact of making a withdrawal.'}
          </p>
          <div className={styles.modalFooterSection}>
            <Button
              size="medium"
              label="Update my goals now"
              to="/your-latest-information"
            />
            <ButtonLink
              label="Update later"
              onClick={(event) => {
                history.push('/withdrawals');
                event.stopPropagation();
              }}
            />
          </div>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
};

CdsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(CdsModal);
