import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './WithdrawalsAppointmentComponent.css';
import BaseGrid from '../BaseGrid/BaseGrid';
import ButtonLink from '../ButtonLink/ButtonLink';
import Button from '../Button/Button';
import {
  calendlyEventScheduledAction,
  withdrawalCalendlyEventScheduled,
  withdrawalCalendlyEventViewed,
} from '../../redux/modules/booking';
import { setWithdrawalAppointmentPending } from '../../redux/modules/withdrawal';

class WithdrawalsAppointmentSection extends React.Component {
  constructor() {
    super();
    this.getCalendlyData = this.getCalendlyData.bind(this);
    this.state = {
      bookingComplete: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const script = document.createElement('script');
    script.src = 'https://calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('message', (e) => {
      this.getCalendlyData.call(this, e);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.getCalendlyData);
  }

  getCalendlyData(e) {
    const {
      dispatchWithdrawalCalendlyViewed,
      dispatchWithdrawalCalendlyScheduled,
      dispatchSetWithdrawalAppointmentPending,
    } = this.props;
    const { data } = e;
    if (data.event && data.event.indexOf('calendly') === 0) {
      if (data.event === 'calendly.event_type_viewed') {
        dispatchWithdrawalCalendlyViewed();
      }
      if (data.event === 'calendly.event_scheduled') {
        dispatchWithdrawalCalendlyScheduled();
        this.setState({ bookingComplete: true });
        dispatchSetWithdrawalAppointmentPending();
      }
    }
    return undefined;
  }

  render() {
    const {
      firstName,
      lastName,
      a1,
      a2,
      email,
      baseCalendlyUrl,
      duration,
    } = this.props;

    const { bookingComplete } = this.state;

    const queryParams = ['hide_gdpr_banner=1'];

    let dataUrl = `${baseCalendlyUrl}-${duration}`;

    if (firstName) {
      queryParams.push(`first_name=${encodeURI(firstName)}`);
    }

    if (lastName) {
      queryParams.push(`last_name=${encodeURI(lastName)}`);
    }

    if (a1) {
      queryParams.push(`a1=${a1}`);
    }

    if (a2) {
      queryParams.push(`a2=${a2}`);
    }

    if (email) {
      queryParams.push(`email=${encodeURI(email)}`);
    }

    if (queryParams.length > 0) {
      dataUrl += `?${queryParams.join('&')}`;
    }

    return (
      <BaseGrid>
        <div className={styles.container}>
          <ButtonLink
            to="/"
            label="← Back"
          />
          <br />
          <section className={bookingComplete ? styles.section1 : styles.section}>
            <div
              className="calendly-inline-widget calendar"
              data-url={dataUrl}
              style={
              {
                border: 0,
                left: 0,
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '100%',
              }
            }
            />
          </section>
          {bookingComplete && (
            <div>
              <div className={styles.returnButton}>
                <Button
                  size="large"
                  label="View my dashboard"
                  to="/"
                />
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                {' '}
                <ButtonLink
                  variant="primary"
                  label="Return to pension withdrawals"
                  to="/withdrawals"
                  font="bodyOne"
                />
              </div>
            </div>
          )}
        </div>
      </BaseGrid>
    );
  }
}

WithdrawalsAppointmentSection.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  a1: PropTypes.string.isRequired,
  a2: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  baseCalendlyUrl: PropTypes.string.isRequired,
};

const mapDispatchToProps = ({
  dispatchCalendlyEventScheduled: calendlyEventScheduledAction,
  dispatchWithdrawalCalendlyScheduled: withdrawalCalendlyEventScheduled,
  dispatchWithdrawalCalendlyViewed: withdrawalCalendlyEventViewed,
  dispatchSetWithdrawalAppointmentPending: setWithdrawalAppointmentPending,
});

export default connect(null, mapDispatchToProps)(WithdrawalsAppointmentSection);
