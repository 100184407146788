import { setup } from 'xstate';

export const flowId = 'importantConsiderations';

export const Steps = {
  assessingPension: 'assessingPension',
  importantConsiderations: 'importantConsiderations',
  withdrawalAdvice: 'withdrawalAdvice',
  taxImplications: 'taxImplications',
  withdrawalCharges: 'withdrawalCharges',
  withdrawalInvestment: 'withdrawalInvestment',
  benefits: 'benefits',
  debt: 'debt',
  health: 'health',
  alternatives: 'alternatives',
  combinePensions: 'combinePensions',
};

export const InitContext = {};

export const machine = setup({
  types: {
    context: {},
    events: {},
  },
  guards: {},
}).createMachine({
  id: `${flowId}-machine`,
  initial: Steps.assessingPension,
  context: InitContext,
  states: {
    assessingPension: {
      meta: { index: 1 },
      on: {
        next: {
          target: Steps.importantConsiderations,
        },
      },
    },
    importantConsiderations: {
      meta: { index: 2 },
      on: {
        next: {
          target: Steps.withdrawalAdvice,
        },
        back: {
          target: Steps.assessingPension,
        },
      },
    },
    withdrawalAdvice: {
      meta: { index: 3 },
      on: {
        next: {
          target: Steps.taxImplications,
        },
        back: {
          target: Steps.importantConsiderations,
        },
      },
    },
    taxImplications: {
      meta: { index: 4 },
      on: {
        next: {
          target: Steps.withdrawalCharges,
        },
        back: {
          target: Steps.withdrawalAdvice,
        },
      },
    },
    withdrawalCharges: {
      meta: { index: 5 },
      on: {
        next: {
          target: Steps.withdrawalInvestment,
        },
        back: {
          target: Steps.taxImplications,
        },
      },
    },
    withdrawalInvestment: {
      meta: { index: 6 },
      on: {
        next: {
          target: Steps.benefits,
        },
        back: {
          target: Steps.withdrawalCharges,
        },
      },
    },
    benefits: {
      meta: { index: 7 },
      on: {
        next: {
          target: Steps.debt,
        },
        back: {
          target: Steps.withdrawalInvestment,
        },
      },
    },
    debt: {
      meta: { index: 8 },
      on: {
        next: {
          target: Steps.health,
        },
        back: {
          target: Steps.benefits,
        },
      },
    },
    health: {
      meta: { index: 9 },
      on: {
        next: {
          target: Steps.alternatives,
        },
        back: {
          target: Steps.debt,
        },
      },
    },
    alternatives: {
      meta: { index: 10 },
      on: {
        next: {
          target: Steps.combinePensions,
        },
        back: {
          target: Steps.health,
        },
      },
    },
    combinePensions: {
      meta: { index: 11 },
      on: {
        back: {
          target: Steps.alternatives,
        },
      },
    },
  },
});
