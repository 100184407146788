/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import {
  getInvestmentAdviceOnboarding,
} from './investmentAdvice';

export const getIsVulnerableCustomer = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const proceed = get(
    onboarding, 'customerDueDiligence.vulnerableCustomer.proceed',
  );
  const pass = get(
    onboarding, 'customerDueDiligence.vulnerableCustomer.pass',
  );
  return pass === false && proceed === false;
};

export const getIsVulnerableWatchListCustomer = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const tags = get(
    onboarding, 'tags',
  ) || [];
  return tags.includes('vulnerability-watchlist');
};

export const getIsVulnerableCustomerResolved = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const proceed = get(
    onboarding, 'customerDueDiligence.vulnerableCustomer.proceed',
  );
  const pass = get(
    onboarding, 'customerDueDiligence.vulnerableCustomer.pass',
  );
  return pass === false && proceed;
};
