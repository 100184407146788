import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';
import { getFirebaseUid } from '../../redux/selectors';
import { setWithdrawalMiniJourneyVisited } from '../../redux/modules/notificationTracking';

import LandscapeImage from '../../assets/images/withdrawals/landscape.svg';
import AddPensions from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';

import styles from './WithdrawalMiniJourney.css';

const CombinePensions = ({
  uid,
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchBackClicked,
  dispatchSetWithdrawalMiniJourneyVisited,
}) => {
  useEffect(() => {
    dispatchSetWithdrawalMiniJourneyVisited(uid);
  }, []);
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={LandscapeImage} alt="landscape" />
        </div>
        <h2 className={styles.headingTwo}>{'Consolidating your pensions for retirement'}</h2>
        <div className={styles.withdrawalText}>{'Do you have any other pensions that you would like to combine? We can help you to find, check and transfer your old pensions and consolidate into one, new Profile Pensions plan.'}</div>
        <div className={styles.withdrawalText}>{'Why it’s important to combine old pensions:'}</div>
        <ul className={styles.unorderedList} style={{ paddingInlineStart: '15px' }}>
          <li className={styles.listItem}>{'Have a clear idea how much money you have for retirement'}</li>
          <li className={styles.listItem}>{'Less life admin - fewer statements and personal details to update'}</li>
          <li className={styles.listItem}>{'Older pensions may be less flexible than newer plans meaning you may not be able to access your cash from 55'}</li>
          <li className={styles.listItem}>{'Having several older pension pots could be more expensive, make sure you\'re paying one low fee in a modern pension'}</li>
          <li className={styles.listItem}>{'Single, regular pension drawdown payments in retirement, rather than organising several small payments'}</li>
        </ul>
        <div className={styles.pensionContainer}>
          <div className={styles.pensionCard}>
            <div className={styles.pensionText}>
              <img className={styles.whatHappensNextWidgetImg} alt="add pensions" src={AddPensions} />
              <p className={styles.sectionSummaryHeading}>{'Find & combine old pensions'}</p>
            </div>
            <Button
              size="large"
              label={'Add pensions'}
              to={'/add-pensions'}
            />
          </div>
        </div>
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'CombinePensions'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  {'Your dedicated Pension Adviser is on hand to help. If you have any questions you can '}
                  <span>
                    <ButtonLink
                      variant="primary"
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'message them here'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        <div className={styles.centerContent}>
          <ButtonLink
            label={'Return to pension withdrawals'}
            onClick={() => onNext()}
            wrapperStyles={{ margin: 0 }}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
  dispatchSetWithdrawalMiniJourneyVisited: setWithdrawalMiniJourneyVisited,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CombinePensions));
