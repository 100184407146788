import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import TabCard from '../../components/TabCard/TabCard';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';

import styles from './WithdrawalPage.css';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { backClickedAction, setImportantConsiderationsRoutingAction } from '../../redux/modules/withdrawal';

const WithdrawalOptions = ({
  history,
  step,
  totalSteps,
  onNext,
  onBack,
  defaultOption,
  onChangeType,
  dispatchBackClickedAction,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (withdrawalType) => {
    if (withdrawalType === 'regular') {
      history.push({ pathname: '/inbox/new-message' });
    } else {
      onNext();
    }
  };

  const [currentTab, setCurrentTab] = useState(defaultOption === 'regular' ? 1 : 0);

  const transferOptionData = [
    {
      tabHeading: <span>{'One-off withdrawal'}</span>,
      key: 'one-off-withdrawal',
      content: (
        <div>
          <p className={styles.body}>{'Tell us how much you’d like to withdraw and we’ll guide you through how that will impact your income in retirement and ways to reduce this impact. We can then arrange to make the payment directly to your bank account.'}</p>
          <h4 className={styles.summaryHeading}>{'Important considerations'}</h4>
          <ul className={styles.importantConsiderations}>
            <li className={styles.importantConsiderationsItem}>{'Impact on your income in retirement'}</li>
            <li className={styles.importantConsiderationsItem}>{'Tax implications'}</li>
            <li className={styles.importantConsiderationsItem}>{'Current market conditions'}</li>
            <li className={styles.importantConsiderationsItem}>{'Benefits of making contributions and pension consolidation'}</li>
          </ul>
          <div className={styles.buttonContainer}>
            <Button
              size="large"
              label="I select this option"
              onClick={() => handleClick('lump-sum')}
            />
          </div>
        </div>
      ),
      tabHeadingOnClickEvent: () => onChangeType('lump-sum'),
    },
    {
      tabHeading: <span>{'Regular withdrawals'}</span>,
      key: 'regular-withdrawal',
      content: (
        <div>
          <p className={styles.body}>{'Starting to take a regular income from your pension is an important decision and we can guide you through this process. Please message your Pension Adviser to book an appointment to discuss your withdrawals.'}</p>
          <Button
            size="large"
            label="Message my Pension Adviser"
            onClick={() => handleClick('regular')}
          />
        </div>
      ),
      tabHeadingOnClickEvent: () => onChangeType('regular'),
    },
  ];

  const pensionAdviserContent = () => {
    if (currentTab === 0) {
      return (
        <div className={styles.contactUsInnerContent}>
          {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
          <span>
            <ButtonLink
              variant="primary"
              to="/inbox/new-message"
              search={'?subject=Withdrawal%20Request'}
              font="inherit"
              displayAsHref
              label={'message them here'}
            />
            {'.'}
          </span>
        </div>
      );
    }
    return (
      <div className={styles.contactUsInnerContent}>
        {'Your dedicated Pension Adviser is on hand to help if you have any questions or want to discuss your withdrawal.'}
      </div>
    );
  };

  return (
    <BaseGrid greyTheme tabletTwelveColumn>
      <div className={styles.withdrawalOptionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <div className={styles.withdrawalOptionsHeadingContainer}>
          <h2 className={styles.withdrawalOptionsHeading}>{'Select an option'}</h2>
          <div>
            <ButtonLink
              onClick={(event) => {
                onBack();
                dispatchBackClickedAction();
                event.stopPropagation();
              }}
              label="← Back"
            />
          </div>
        </div>
        <div className={styles.withdrawalOptionsTabCardContainer}>
          <TabCard
            tabs={transferOptionData}
            transferOption
            defaultTab={currentTab}
            updateParent={setCurrentTab}
          />
        </div>
        <br />
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalAmountStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={pensionAdviserContent()}
            />
          </div>
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchBackClickedAction: backClickedAction,
  dispatchSetImportantConsiderationsRouting: (action) => dispatch(
    setImportantConsiderationsRoutingAction(action),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithdrawalOptions));
