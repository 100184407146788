import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import BooksStackImage from '../../assets/images/withdrawals/books-stack.svg';

import styles from './WithdrawalMiniJourney.css';

const Alternatives = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
  dispatchBackClicked,
  nextButtonText = 'I understand',
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={BooksStackImage} alt="books stack" />
        </div>
        <h2 className={styles.headingTwo}>{'Have you looked at other options to raise the cash you need or have you considered reducing the amount of your withdrawal?'}</h2>
        <div className={styles.withdrawalText}>{'Taking money now will reduce the value of your pension pot which means you will have less money to generate an income in the future which may impact on your standard of living in retirement.'}</div>
        <div className={styles.greenBox}>{'You should consider how you will fund any shortfall in your required income in retirement.'}</div>
        <Button
          size="large"
          label={nextButtonText}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alternatives);
