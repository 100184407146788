import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import FilesImage from '../../assets/images/withdrawals/files.svg';

import styles from './WithdrawalMiniJourney.css';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const Benefits = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
  dispatchBackClicked,
  hideContactUs = false,
  nextButtonText = 'I understand',
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          secondary
          mid
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
          wrapperStyles={{
            margin: '0',
            fontFamily: 'Poppins',
            fontSize: '16px',
          }}
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={FilesImage} alt="files" />
        </div>
        <h2 className={styles.headingTwo}>{'Do you receive any benefits?'}</h2>
        <div className={styles.withdrawalText}>{'Taking money can affect any means tested benefits you receive and may affect how long term care costs are assessed.'}</div>
        <div className={styles.greenBox}>{'If you currently receive means tested benefits, you should contact us to discuss the implication of your withdrawal and how you can reduce the impact of this.'}</div>
        {!hideContactUs && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'CombinePensions'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={nextButtonText}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
