import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import ConversationImage from '../../assets/images/withdrawals/conversation.svg';

import styles from './WithdrawalMiniJourney.css';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const WithdrawalAdvice = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
  dispatchBackClicked,
  hideContactUs = false,
  nextButtonText = 'I understand',
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={ConversationImage} alt="conversation boxes" />
        </div>
        <h2 className={styles.headingTwo}>{'Have you taken any advice about withdrawing money from your pension?'}</h2>
        <div className={styles.withdrawalText}>{'Taking money from your pension is an important decision and you should consider the benefits of taking advice or guidance first.'}</div>
        <div className={styles.greenBox}>
          {'There is a free impartial pension guidance service provided by the government called Pension Wise. Find out more at '}
          <a className={styles.pensionWiseLink} href="https://pensionwise.gov.uk/" target={'_blank'} rel="noopener noreferrer">{'pensionwise.gov.uk'}</a>
          {' or call them on 0800 138 3944'}
        </div>
        <div className={styles.withdrawalText}>{'While we can advise you where to invest your pension, we don’t currently give advice on pension withdrawal. However, your Pension Adviser can help you understand your options and can arrange withdrawals for you.'}</div>
        {!hideContactUs && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'ImportantConsiderations'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={nextButtonText}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalAdvice);
