import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import BarChartImage from '../../assets/images/withdrawals/barchart.svg';

import styles from './WithdrawalMiniJourney.css';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const WithdrawalInvestment = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
  dispatchBackClicked,
  hideContactUs = false,
  nextButtonText = 'I understand',
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={BarChartImage} alt="bar chart" />
        </div>
        <h2 className={styles.headingTwo}>{'Withdrawing the money to invest elsewhere or deposit in a savings or current account'}</h2>
        <div className={styles.withdrawalText}>{'When you make a withdrawal, if the money is left in a savings or current account, inflation may erode its value.'}</div>
        <div className={styles.withdrawalText}>{' If the withdrawal is being made to invest the money elsewhere, you should consider the tax and charges on the new investment and how they compare to the existing plan. By withdrawing from your pension, you are potentially moving to a less tax efficient investment.'}</div>
        <div className={styles.withdrawalText}>{'You should also be aware of investment scams and where you invest.'}</div>
        <div className={styles.greenBox}>{'It may be more tax efficient and offer more growth potential to leave it in your pension to try to protect your money against inflation and continue to keep the money invested which may earn more for your retirement.'}</div>
        {!hideContactUs && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'CombinePensions'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'Your Pension Adviser can help you to understand whether there are better options for your money. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={nextButtonText}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalInvestment);
