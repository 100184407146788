import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import CalenderImage from '../../assets/images/withdrawals/calendar-50.svg';

import styles from './WithdrawalMiniJourney.css';

const AssessingPension = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          secondary
          mid
          onClick={onBack}
          label="← Back"
          wrapperStyles={{
            margin: '0',
            fontFamily: 'Poppins',
            fontSize: '16px',
          }}
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={CalenderImage} alt="calender" />
        </div>
        <h2 className={styles.headingTwo}>{'Are you thinking about accessing your pension?'}</h2>
        <div className={styles.withdrawalText}>{'From the age of 55 you have the option to take money from your pension. Here is some helpful information for your consideration.'}</div>
        <Button
          size="large"
          label={'Continue'}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessingPension);
