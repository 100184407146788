import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import Piechart25Image from '../../assets/images/withdrawals/piechart25.svg';

import styles from './WithdrawalMiniJourney.css';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const TaxImplicationsContinued = ({
  step,
  totalSteps,
  onNext,
  onBack,
  dispatchNextClicked,
  dispatchBackClicked,
  hideContactUs,
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={Piechart25Image} alt="pie chart showing 25%" />
        </div>
        <h2 className={styles.headingTwo}>{'Tax implications of withdrawing from your pension'}</h2>
        <div className={styles.withdrawalText}>{'You can typically withdraw up to 25% from your pension and pay no tax on that money. This is called your Tax Free Cash allowance.'}</div>
        <div className={styles.withdrawalText}>{'Once you exceed this amount, any further withdrawal will be added to your annual income and taxed at your marginal rate of tax. The Money Purchase Annual Allowance (MPAA) will then apply, meaning any further contributions to pensions are subject to a reduced annual allowance of £10,000.'}</div>
        <div className={styles.withdrawalText}>{'You could consider splitting your withdrawal over more than one tax year which could potentially reduce your tax liability.'}</div>
        <div className={styles.greenBox}>{'Withdrawing income from your pension beyond your tax-free cash allowance can impact your tax code, potentially resulting in higher tax rates and unexpected charges. Additionally, an emergency tax may be applied. For further guidance, message your adviser.'}</div>
        {!hideContactUs && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'ImportantConsiderations'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'Your Pension Advisor can help you to understand whether there are better options for your money. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={'I understand'}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxImplicationsContinued);
