/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';

import TextField from '../../components/TextField/TextField';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';

import WithdrawalAccountDetailsImage from '../../assets/images/withdrawals/debit-card.svg';
import ContributionsFscs from '../../assets/images/rebranding/fscs-protected-card.svg';

import styles from './WithdrawalPage.css';

import {
  accountHolderNameChangedAction,
  accountNumberChangedAction,
  bankNameChangedAction,
  rollNumberChangedAction,
  sortCodeChangedAction,
} from '../../redux/modules/accountDetails';

import {
  nextClickedAction,
  backClickedAction,
  withdrawalAccountDetailsStepVisitedAction,
} from '../../redux/modules/withdrawal';

const AccountDetails = ({
  step,
  totalSteps,
  onBack,
  onNext,
  data,
  onChangeName,
  onChangeSortCode,
  onChangeAccountNumber,
  onChangeBankName,
  onChangeRollNo,
  dispatchWithdrawalAccountDetailsStepVisited,
  dispatchSortCodeChanged,
  dispatchAccountHolderNameChanged,
  dispatchAccountNumberChanged,
  dispatchBankNameChanged,
  dispatchRollNumberChanged,
  dispatchNextClicked,
  dispatchBackClicked,
}) => {
  const [_dispatchSortCodeChanged] = useState(() => once(dispatchSortCodeChanged));
  const [_dispatchAccountHolderNameChanged] = useState(() => once(dispatchAccountHolderNameChanged));
  const [_dispatchAccountNumberChanged] = useState(() => once(dispatchAccountNumberChanged));
  const [_dispatchBankNameChanged] = useState(() => once(dispatchBankNameChanged));
  const [_dispatchRollNumberChanged] = useState(() => once(dispatchRollNumberChanged));

  const {
    accountNumber,
    bankName,
    name,
    sortCode,
    rollNo,
  } = data;

  useEffect(() => {
    dispatchWithdrawalAccountDetailsStepVisited();
  }, []);

  const instructionType = 'withdrawal';

  const enableNextButton = () => {
    if (accountNumber && accountNumber.length > 7 && bankName && name && sortCode) {
      return true;
    }

    return false;
  };

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            onBack();
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '155px' }} src={WithdrawalAccountDetailsImage} alt="debit card" />
        </div>
        <h3 className={styles.headingTwo}>{'Account details'}</h3>
        <p className={styles.body}>{'Please provide us with the bank details of the account you would like to withdraw to.'}</p>
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="name"
          label={'Name'}
          defaultValue={'As seen on your account'}
          value={name}
          onChange={(event) => {
            _dispatchAccountHolderNameChanged(instructionType, true);
            onChangeName(event.target.value || '');
          }}
        />
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="sortCode"
          label={'Sort code'}
          defaultValue={'Eg. 13-03-55'}
          value={sortCode}
          onChange={(event) => {
            _dispatchSortCodeChanged(instructionType, true);
            const input = event.target.value;
            const digitsOnly = input.replace(/\D/g, '');
            const digitsOnlyLength = digitsOnly.length;
            if (digitsOnlyLength > 6) return;
            const sortCodeFormatted = digitsOnly.split('').map((digit, index) => {
              const shouldAppendHyphen = (index === 1 && digitsOnly.length > 2)
                || (index === 3 && digitsOnly.length > 4);
              return shouldAppendHyphen ? `${digit}-` : digit;
            }).join('');
            onChangeSortCode(sortCodeFormatted);
          }}
        />
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="accountNumber"
          label={'Account number'}
          helpText="Most account numbers are 8 digits long. If your account number is 7 digits long, add a ‘0’ to the beginning."
          defaultValue={'Eg. 12345678'}
          value={accountNumber}
          onChange={(event) => {
            _dispatchAccountNumberChanged(instructionType, true);
            const input = event.target.value;
            const accountNumberFormatted = input.replace(/\D/g, '');
            const accountNumberLength = accountNumberFormatted.length;
            if (accountNumberLength > 8) return;
            onChangeAccountNumber(accountNumberFormatted);
          }}
        />
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="bankName"
          label={'Bank name'}
          defaultValue={'Eg. Bank of Scotland'}
          value={bankName}
          onChange={(event) => {
            _dispatchBankNameChanged(instructionType, true);
            onChangeBankName(event.target.value);
          }}
        />
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="rollNo"
          label={'Building Society Roll Number (If you have one)'}
          defaultValue={'Eg. 12345678'}
          value={rollNo}
          onChange={(event) => {
            _dispatchRollNumberChanged(instructionType, true);
            onChangeRollNo(event.target.value);
          }}
        />
        <div className={styles.withdrawalFscsImgContainer}>
          <img src={ContributionsFscs} alt="FSCS" />
        </div>
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalAccountDetailsStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal '}
                  <span>
                    <ButtonLink
                      variant="primary"
                      displayAsHref
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'message them here'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        <Button
          size="large"
          disabled={!enableNextButton()}
          label={'Next'}
          onClick={() => {
            dispatchNextClicked();
            onNext();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchWithdrawalAccountDetailsStepVisited: withdrawalAccountDetailsStepVisitedAction,
  dispatchSortCodeChanged: sortCodeChangedAction,
  dispatchAccountHolderNameChanged: accountHolderNameChangedAction,
  dispatchAccountNumberChanged: accountNumberChangedAction,
  dispatchBankNameChanged: bankNameChangedAction,
  dispatchRollNumberChanged: rollNumberChangedAction,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
