import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import withdrawalPlantImg from '../../assets/images/retirementIncome/withdrawal-plant.svg';
import chevronRight from '../../assets/images/rebranding/chevron-right.svg';

import {
  withdrawalAppointmentBookClickedAction,
  backClickedAction,
  withdrawalMessageAdvisorButtonClickedAction,
  withdrawalImportantConsiderationsClickedAction,
  unsetWithdrawalAppointmentPending,
} from '../../redux/modules/withdrawal';
import { getIsAppointmentPending } from '../../redux/selectors/investmentAdvice';
import { getUserBookings } from '../../redux/selectors/ongoingService';

import styles from '../WithdrawalsHubPage/WithdrawalsHubPage.css';

const Booking = ({
  appointmentPending,
  appointmentIsTodayOrInTheFuture,
  withdrawalAppointmentBookClicked,
}) => {
  const appointment = get(appointmentIsTodayOrInTheFuture, 'when');

  const appointmentContent = (
    <React.Fragment>
      <div className={styles.withdrawalText}>
        {'Withdrawing from your pension is a major decision, we’re with you every step of the way.'}
      </div>
      <div className={styles.bookingInfoBox}>
        <div className={styles.withdrawalText}>{'You are scheduled to speak to your adviser on:'}</div>
        <div className={styles.appointmentTimeText}>{moment(appointment).format('h:mm a')}</div>
        <div className={styles.appointmentTimeText}>{moment(appointment).format('dddd, DD MMMM YYYY')}</div>
      </div>
    </React.Fragment>
  );

  const makeAppointmentContent = (
    <React.Fragment>
      <div className={styles.withdrawalText}>
        {'Withdrawing from your pension is a major decision that will have an impact on your income in retirement. We’re with you every step of the way. Start by booking a call to discuss your personal circumstances.'}
      </div>
      <Button
        size="large"
        label="Book my call"
        onClick={() => {
          withdrawalAppointmentBookClicked();
        }}
        to={'/withdrawals-appointment'}
      />
    </React.Fragment>
  );

  const contentToRender = appointmentIsTodayOrInTheFuture
    ? appointmentContent
    : makeAppointmentContent;

  return (
    <React.Fragment>
      <p className={styles.withdrawalSubHeading}>
        {'Your Personal Adviser is on hand to discuss your options'}
      </p>
      {appointmentPending ? <Loader /> : contentToRender}
    </React.Fragment>
  );
};

const WithdrawalBooking = ({
  onBack,
  dispatchWithdrawalAppointmentBookClicked,
  dispatchBackClicked,
  dispatchWithdrawalImportantConsiderationsClicked,
  dispatchWithdrawalMessageAdvisorButtonClicked,
  dispatchUnsetWithdrawalAppointmentPending,
  userBookings,
  appointmentPending,
  history,
}) => {
  const appointmentIsTodayOrInTheFuture = userBookings.length > 0
    ? userBookings.find((b) => {
      const twoHoursBuffer = moment(b.when).add(2, 'hours');
      return moment(twoHoursBuffer).isSameOrAfter(moment()) && !b.void && !b.voidReason;
    })
    : null;

  useEffect(() => {
    if (appointmentIsTodayOrInTheFuture) {
      dispatchUnsetWithdrawalAppointmentPending();
    }
  }, [userBookings]);

  return (
    <BaseGrid addPensionsTheme greyTheme>
      <div className={styles.container}>
        <ButtonLink
          label="← Back"
          onClick={() => {
            dispatchBackClicked();
            onBack();
          }}
        />
        <div className={classnames(styles.roundedDiv)}>
          <div className={styles.roundedDivHeading}>
            <img
              src={withdrawalPlantImg}
              alt={'Withdrawal plant'}
            />
            <h1 className={styles.withdrawalHeading}>{'Pension withdrawals'}</h1>
          </div>
          <Booking
            appointmentPending={appointmentPending}
            appointmentIsTodayOrInTheFuture={appointmentIsTodayOrInTheFuture}
            withdrawalAppointmentBookClicked={dispatchWithdrawalAppointmentBookClicked}
          />
        </div>
        <button
          className={classnames(styles.informationCard)}
          type="button"
          to="/important-considerations"
          onClick={() => {
            dispatchWithdrawalImportantConsiderationsClicked();
            history.push('/important-considerations');
          }}
        >
          <p className={styles.withdrawalInfoHeading}>{'Important considerations'}</p>
          <div className={styles.chevronRight}>
            <img src={chevronRight} alt="Right Chevron" />
          </div>
        </button>
        <div className={styles.withdrawalContactUsWrapper}>
          <ContactUsContent
            showButton={false}
            headingRowStyles={{ alignItems: 'flex-start' }}
            customMessage={(
              <p
                className={styles.withdrawalContactUsMessage}
              >
                {'Your dedicated Pension Adviser is on hand to help. If you have any questions or want to discuss your withdrawal, '}
                <ButtonLink
                  variant="primary"
                  label="message them here"
                  to="/inbox/new-message"
                  search={'?subject=Withdrawal%20Request'}
                  displayAsHref
                  font="bodyOne"
                  onClick={dispatchWithdrawalMessageAdvisorButtonClicked}
                />
                {'.'}
              </p>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

WithdrawalBooking.propTypes = {};

const mapStateToProps = (state) => ({
  userBookings: getUserBookings(state),
  appointmentPending: getIsAppointmentPending(state),
});

const mapDispatchToProps = {
  dispatchWithdrawalAppointmentBookClicked: withdrawalAppointmentBookClickedAction,
  dispatchBackClicked: backClickedAction,
  dispatchWithdrawalMessageAdvisorButtonClicked: withdrawalMessageAdvisorButtonClickedAction,
  dispatchWithdrawalImportantConsiderationsClicked: withdrawalImportantConsiderationsClickedAction,
  dispatchUnsetWithdrawalAppointmentPending: unsetWithdrawalAppointmentPending,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(WithdrawalBooking);
