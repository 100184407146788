import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import AssessingPension from './AssessingPension';
import ImportantConsiderations from './ImportantConsiderations';
import TaxImplications from './TaxImplications';
import TaxImplicationsContinued from './TaxImplicationsContinued';
import WithdrawalAdvice from './WithdrawalAdvice';
import WithdrawalCharges from './WithdrawalCharges';
import WithdrawalInvestment from './WithdrawalInvestment';
import Benefits from './Benefits';
import Health from './Health';
import Debt from './Debt';
import WithdrawalAmount from '../WithdrawalPage/WithdrawalAmount';
import WithdrawalOptions from '../WithdrawalPage/WithdrawalOptions';
import WithdrawalReason from '../WithdrawalPage/WithdrawalReason';
import WithdrawalResults from '../WithdrawalPage/WithdrawalResults';
import WithdrawalOtherOptions from '../WithdrawalPage/WithdrawalsOtherOptions';
import WithdrawalInfluenced from '../WithdrawalPage/WithdrawalInfluenced';
import WithdrawalAccountDetails from '../WithdrawalPage/WithdrawalAccountDetails';
import WithdrawalReviewDetails from '../WithdrawalPage/WithdrawalReviewDetails';
import WithdrawalRequestComplete from '../WithdrawalPage/WithdrawalRequestComplete';
import WithdrawalInProgress from './WithdrawalInProgress';
import WithdrawalBooking from './WithdrawalBooking';

import { getInProgressWithdrawalLumpSumInstructions, getCanRequestOnlineWithdrawal } from '../../redux/selectors/investmentAdvice';
import { backClickedAction } from '../../redux/modules/withdrawal';
import { getIsVulnerableCustomer, getIsVulnerableWatchListCustomer } from '../../redux/selectors/vulnerableCustomer';
import { getUserBookings } from '../../redux/selectors/ongoingService';
import { machine, Steps, flowId } from './machine/withdrawals';

import { getLatestPensionValue, getCanFetchEstimatedIncome } from '../../redux/selectors';

const WithdrawalMiniJourney = ({
  history,
  userBookings,
  isVulnerableCustomer,
  inProgressWithdrawalLumpSumInstructions,
  latestPensionValue,
  canRequestOnlineWithdrawal,
  canFetchEstimatedIncome,
  dispatchBackClicked,
  onVulnerableWatchlist
}) => {
  const totalSteps = Object.keys(Steps).length + 1;

  const [state, send] = useMachine(machine, {
    input: {
      balanceAtRequestTime: latestPensionValue,
      canRequestOnlineWithdrawal,
      canFetchEstimatedIncome,
      onVulnerableWatchlist
    },
  });

  const stateMeta = state.getMeta();
  const machineStepMeta = `${flowId}-machine.${state.value}`;
  const currentStepIndex = get(stateMeta[machineStepMeta], 'index', 1);

  const showAppointmentPending = userBookings.length > 0
    ? userBookings.find((b) => {
      const twoHoursBuffer = moment(b.when).add(2, 'hours');
      return moment(twoHoursBuffer).isSameOrAfter(moment()) && !b.void && !b.voidReason;
    })
    : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  useEffect(() => {
    const showWithdrawalInProgress = Boolean(inProgressWithdrawalLumpSumInstructions.length);

    if (isVulnerableCustomer) {
      send({ type: 'vulnerableCustomer' });
    } else if (showWithdrawalInProgress) {
      send({ type: 'withdrawalInProgress' });
    } else if (showAppointmentPending) {
      send({ type: 'appointmentPending' });
    }
  }, [showAppointmentPending]);
  // We only need showAppointmentPending in the deps, vulnerableCustomer and withdrawalInProgress
  // are only required when we first load for example, adding showWithdrawalInProgress to deps
  // could cause the withdrawalInProgress page to show instead of withdrawalConfirmation step.

  return (
    <>
      {state.matches(Steps.assessingPension) && (
        <AssessingPension
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => {
            dispatchBackClicked();
            history.push('/');
          }}
        />
      )}
      {state.matches(Steps.importantConsiderations) && (
        <ImportantConsiderations
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalAdvice) && (
        <WithdrawalAdvice
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.taxImplications) && (
        <TaxImplications
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalCharges) && (
        <WithdrawalCharges
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalInvestment) && (
        <WithdrawalInvestment
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.benefits) && (
        <Benefits
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.debt) && (
        <Debt
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.health) && (
        <Health
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalOptions) && (
        <WithdrawalOptions
          step={currentStepIndex}
          totalSteps={totalSteps}
          defaultOption={state.context.withdrawalType}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          onChangeType={(withdrawalType) => send({
            type: 'changeType',
            fullWithdrawalType: `customer-withdrawal-${withdrawalType}`,
            withdrawalType,
          })}
        />
      )}
      {state.matches(Steps.withdrawalAmount) && (
        <WithdrawalAmount
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => {
            send({
              type: state.context.type === 'regular' ? 'bookAppointment' : 'nextLumpSum',
            });
          }}
          onBack={() => send({ type: 'back' })}
          changeWithdrawalAmount={(withdrawalAmount, withdrawalAmountMask) => {
            send({
              type: 'changeWithdrawalAmount',
              withdrawalAmount,
              withdrawalAmountMask,
            });
          }}
          withdrawalAmount={state.context.withdrawalAmount}
          withdrawalAmountMask={state.context.withdrawalAmountMask}
        />
      )}
      {state.matches(Steps.bookAppointment) && (
        <WithdrawalBooking
          step={currentStepIndex}
          totalSteps={totalSteps}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withDrawalReason) && (
        <WithdrawalReason
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          onChangeWithdrawalReason={(otherTextValue, fundsUsedForSelectedOption) => {
            send({
              type: 'changeWithdrawalReason',
              fundsUsedFor: otherTextValue || '',
              fundsUsedForSelectedOption:
                fundsUsedForSelectedOption || state.context.fundsUsedForSelectedOption,
            });
          }}
          fundsUsedFor={state.context.fundsUsedFor}
          fundsUsedForSelectedOption={state.context.fundsUsedForSelectedOption}
        />
      )}
      {state.matches(Steps.withDrawalResults) && (
        <WithdrawalResults
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          onChangeCompletePensionRequestSelected={(completePensionRequestSelected) => {
            send({
              type: 'changeCompletePensionRequestSelected',
              completePensionRequestSelected,
            });
          }}
          onChangeCompleteContributionRequestSelected={(completeContributionRequestSelected) => {
            send({
              type: 'changeCompleteContributionRequestSelected',
              completeContributionRequestSelected,
            });
          }}
          withdrawalAmount={state.context.withdrawalAmount}
          completePensionRequestSelected={state.context.completePensionRequestSelected}
          completeContributionRequestSelected={state.context.completeContributionRequestSelected}
        />
      )}
      {state.matches(Steps.withdrawalOtherOptions) && (
        <WithdrawalOtherOptions
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalInfluenced) && (
        <WithdrawalInfluenced
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.taxImplicationsContinued) && (
        <TaxImplicationsContinued
          step={currentStepIndex}
          totalSteps={totalSteps}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
        />
      )}
      {state.matches(Steps.withdrawalAccountDetails) && (
        <WithdrawalAccountDetails
          step={currentStepIndex}
          totalSteps={totalSteps}
          data={state.context}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          onChangeName={(name) => send({ type: 'changeName', name })}
          onChangeSortCode={(sortCode) => send({ type: 'changeSortCode', sortCode })}
          onChangeAccountNumber={(accountNumber) => send({ type: 'changeAccountNumber', accountNumber })}
          onChangeBankName={(bankName) => send({ type: 'changeBankName', bankName })}
          onChangeRollNo={(rollNo) => send({ type: 'changeRollNo', rollNo })}
        />
      )}
      {state.matches(Steps.withdrawalConfirmation) && (
        <WithdrawalReviewDetails
          step={currentStepIndex}
          totalSteps={totalSteps}
          data={state.context}
          onNext={() => send({ type: 'next' })}
          onBack={() => send({ type: 'back' })}
          onChangeConfirmWithdrawal={(confirmWithdrawal) => {
            send({
              type: 'changeConfirmWithdrawal',
              confirmWithdrawal,
            });
          }}
          onChangeUnderstandWithdrawal={(understandWithdrawal) => {
            send({
              type: 'changeUnderstandWithdrawal',
              understandWithdrawal,
            });
          }}
          onChangeConfirmWithdrawalAdvice={(confirmWithdrawalAdvice) => {
            send({
              type: 'changeConfirmWithdrawalAdvice',
              confirmWithdrawalAdvice,
            });
          }}
          onChangeAccountDetails={() => send({ type: 'changeAccountDetails' })}
          onChangeWithdrawalAmount={() => send({ type: 'changeWithdrawalAmount' })}
        />
      )}
      {state.matches(Steps.withdrawalRequestComplete) && (
        <WithdrawalRequestComplete
          step={currentStepIndex}
          totalSteps={totalSteps}
        />
      )}
      {/* Three possible states that prevent withdrawal flow */}
      {state.matches(Steps.vulnerableCustomer) && (
        <WithdrawalBooking
          onBack={() => {
            dispatchBackClicked();
            history.push('/');
          }}
        />
      )}
      {state.matches(Steps.withdrawalInProgress) && (
        <WithdrawalInProgress
          onBack={() => {
            dispatchBackClicked();
            history.push('/');
          }}
        />
      )}
      {state.matches(Steps.appointmentPending) && (
        <WithdrawalBooking
          onBack={() => {
            dispatchBackClicked();
            history.push('/');
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isVulnerableCustomer: getIsVulnerableCustomer(state),
  inProgressWithdrawalLumpSumInstructions: getInProgressWithdrawalLumpSumInstructions(state),
  userBookings: getUserBookings(state),
  latestPensionValue: getLatestPensionValue(state),
  canRequestOnlineWithdrawal: getCanRequestOnlineWithdrawal(state),
  canFetchEstimatedIncome: getCanFetchEstimatedIncome(state),
  onVulnerableWatchlist: getIsVulnerableWatchListCustomer(state)
});

const mapDispatchToProps = {
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithdrawalMiniJourney));
